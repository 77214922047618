import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"

export default class PasswordVerification extends Controller {
  static targets = ["password", "passwordConfirmation", "submit", "errorMessage"]

  checkPassword(event) {
    if (this.passwordTarget.value === this.passwordConfirmationTarget.value) {
      this.markAsValid()
    } else {
      this.markAsInvalid()
    }
  }

  // #### Internal methods ####

  markAsInvalid() {
    this.submitTarget.disabled = true
    this.errorMessageTarget.classList.remove(HIDDEN_CLASS)
  }

  markAsValid() {
    this.submitTarget.disabled = false
    this.errorMessageTarget.classList.add(HIDDEN_CLASS)
  }
}
